import { FC } from 'react';
import classNames from 'classnames';
import { HStack, Text } from '@chakra-ui/react';
import './carrier-filter-header.scss';
import { UiButtonOutlined } from '@/ui-elements';
import { useIcons } from '@/hooks/use-icons';
import { ModalAction, useModal } from '@/hooks/use-modal';
import { ModalFormType, ModalType } from '@/types/common/modals';

type Props = {
  isExpanded?: boolean;
  onClickExpand?: VoidFunction;
};

export const CarrierFilterHeader: FC<Props> = ({ isExpanded = true, onClickExpand }) => {
  const { CarType, ArrowUp, Organisation, Profile } = useIcons();

  const { modalView: orgModal, setModalOpened: showOrgModal } = useModal<{ type: ModalFormType } & ModalAction>(ModalType.CreateCarrier, {
    type: 'org',
  });
  const { modalView: vehicleModal, setModalOpened: showVehicleModal } = useModal<{ type: ModalFormType } & ModalAction>(ModalType.Form, {
    type: 'vehicle',
  });
  const { modalView: driverModal, setModalOpened: showDriverModal } = useModal<{ type: ModalFormType } & ModalAction>(ModalType.Form, {
    type: 'driver',
  });

  return (
    <HStack className="carrier-filter__header" cursor="default">
      <HStack className="carrier-filter__header-buttons">
        <UiButtonOutlined onClick={() => showOrgModal(true)} leftIcon={Organisation} mode="dark">
          Добавить Организацию
        </UiButtonOutlined>
        <UiButtonOutlined onClick={() => showVehicleModal(true)} leftIcon={CarType} mode="dark">
          Добавить Авто
        </UiButtonOutlined>
        <UiButtonOutlined onClick={() => showDriverModal(true)} leftIcon={Profile} mode="dark">
          Добавить Водителя
        </UiButtonOutlined>
      </HStack>
      <HStack className={classNames('carrier-filter__header-button-collapse', { expanded: !isExpanded })} onClick={onClickExpand} cursor="pointer">
        <ArrowUp />
        <Text>{isExpanded ? 'Свернуть' : 'Развернуть'}</Text>
      </HStack>
      {driverModal}
      {orgModal}
      {vehicleModal}
    </HStack>
  );
};
