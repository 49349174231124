import { FC } from 'react';
import { PageWrapper } from 'pages/page-wrapper';
import { Flex } from '@chakra-ui/react';
import { OrderSidebar } from '@/views/order-sidebar/order-sidebar';
import { OrderListView } from '@/views/order-list/order-list-view';

export const OrdersPage: FC = () => (
  <PageWrapper>
    <Flex>
      <OrderListView />
      <OrderSidebar />
    </Flex>
  </PageWrapper>
);
