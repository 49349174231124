import { AccountRole } from '@/types/api/account-role';
import { UserRole } from '@/types/api/user-role';
import { NavigationMenu } from '@/types/routes/navigation-menu';
import { checkAccountRole } from '@/helpers/routes/check-account-role';

export const getMenuRoutes = (navigationMenu: NavigationMenu, role?: UserRole, accountRole?: AccountRole | Array<AccountRole>): NavigationMenu => ({
  items: navigationMenu.items
    .filter(
      (x) =>
        x.route.availableRoles?.includes(role) &&
        checkAccountRole({
          accountRole,
          availableAccountRoles: x.route.availableAccountRoles,
        })
    )
    .sort((a, b) => a.sortingOrder - b.sortingOrder),
});
